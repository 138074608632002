 export const environment = {
  applicationStageCount: 8,
  apiUrl: 'https://api.int.thda.prolinkplus.prolinksolutions.com/api',
  clientName: 'thda',
  cognitoAuthenticationFlowtype: 'USER_SRP_AUTH',
  cognitoRegion: 'us-east-1',
  cognitoUserPoolId: 'us-east-1_WcNY59WzD',
  cognitoUserPoolWebClientId: '1lfc4d3qq9kl1dlreu1n859q5n',
  googleAnalyticsId: '',
  hotjarId: '',
  loadZenDesk: true,
  production: false,
  allowLanguageChanges: false,
  recaptchaSiteKey: '6LeJGXkbAAAAAHNKMsoJImyw2r9AqddgjFkzfnuW',
  setTimeout: 30,
  smartyStreetsKey: '',
  stateAbbreviation: 'TN',
  stateId: 43,
  timeOut: 1800,
  timestamp: '12/11/2023 11:10:35 PM',
  title: 'Tennessee HAF - ProLink+',
  uploadFileSize: 50000000,
  zenDeskUrl: 'https://static.zdassets.com/ekr/snippet.js?key=6d19b8da-62e7-4d18-864e-1f7db58e670e',
  showReasonableAccommodation: true,
  showLanguageAssistance: true,
  showDiscriminationComplaints: true,
  showLoginPageHeroBanner: true,
  supportedLanguages: [ { name: 'English', value:'en'}, { name: 'Español', value:'es'}]
};
